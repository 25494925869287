* {
  -webkit-appearance: none;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
button,
input,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a:link {
  color: inherit;
  text-decoration: none;
}
a:visited {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
button {
  border: solid 0;
}
input {
  border: solid 0;
}
textarea {
  border: solid 0;
}

html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #f5f5f5;
	font-family: 'Yu Gothic', '\6E38\30B4\30B7\30C3\30AF', YuGothic, '\6E38\30B4\30B7\30C3\30AF\4F53',
		'\30D2\30E9\30AE\30CE\89D2\30B4   Pro W3', '\30E1\30A4\30EA\30AA', sans-serif;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
	font-family: 'Yu Gothic', '\6E38\30B4\30B7\30C3\30AF', YuGothic, '\6E38\30B4\30B7\30C3\30AF\4F53',
		'\30D2\30E9\30AE\30CE\89D2\30B4   Pro W3', '\30E1\30A4\30EA\30AA', sans-serif;
}

button.contained {
	background-color: #df2357;
}
div.gradient-card {
	background-image: linear-gradient(
		135deg,
		#e59664 0%,
		#ee5465 50%,
		#c72e78 100%
	);
}

.gradient-peach {
	background-image: linear-gradient(90deg, #ff5c77 0%, #ee5465 100%);
}

.gradient-dark-peach {
	background-image: linear-gradient(90deg, #d23c55 0%, #ca2b3d 100%);
}

a {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
}

#frame {
	width: 320px;
	margin: 0 auto;
}

.stripe-small {
	background-image: linear-gradient(
		45deg,
		#e46ca0 25%,
		#e15c96 25%,
		#e15c96 50%,
		#e46ca0 50%,
		#e46ca0 75%,
		#e15c96 75%,
		#e15c96
	);
	/* 幅8px、高さ8pxで背景画像のサイズを指定 */
	background-size: 8px 8px;
}

textarea {
}

::-webkit-input-placeholder {
	/* Chrome */
	font-size: 12px;
	color: #787878;
	line-height: 2;
}
:-ms-input-placeholder {
	/* IE 10+ */
	font-size: 12px;
	color: #787878;
	line-height: 2;
}
::-moz-placeholder {
	/* Firefox 19+ */
	font-size: 12px;
	color: #787878;
	line-height: 2;
	opacity: 1;
}
:-moz-placeholder {
	/* Firefox 4 - 18 */
	font-size: 12px;
	color: #787878;
	line-height: 2;
	opacity: 1;
}

.stripe-large {
	background-image: linear-gradient(
		45deg,
		#e46ca0 25%,
		#e15c96 25%,
		#e15c96 50%,
		#e46ca0 50%,
		#e46ca0 75%,
		#e15c96 75%,
		#e15c96
	);
	/* 幅8px、高さ8pxで背景画像のサイズを指定 */
	background-size: 16px 16px;
}

.gradient-green {
	background-image: linear-gradient(135deg, #0ab3d2 0%, #1c8f38 100%);
}

.gradient-red {
	background-image: linear-gradient(135deg, #cb1b30 0%, #9b0b1c 100%);
}

.markdown ul li {
	list-style-type: disc;
	color: #165d7e;
	margin-left: 16px;
	font-size: 13px;
	line-height: 1.5;
}

.markdown ol li {
	list-style-type: decimal;
	color: #165d7e;
	margin-left: 16px;
	font-size: 13px;
	line-height: 1.5;
}

.markdown ol li ol li {
	list-style-type: lower-alpha;
}

.markdown ol > li > *,
ul > li > * {
	margin-bottom: 8px;
}

.markdown li {
	margin-bottom: 8px;
}

.markdown li:first-child {
	margin-top: 8px;
}

.markdown p {
	color: #165d7e;
	font-size: 13px;
	line-height: 1.8;
}

.markdown th,
td {
	font-size: 13px;
	line-height: 1.8;
}

.markdown a {
	text-decoration: underline;
}

.markdown p > code,
pre {
	border-radius: 4px;
	padding: 4px;
	background-color: #fcfcfc;
	border: 1px solid #c8c8c8;
	font-size: 13px;
	color: #dc267e;
	line-height: 1.5;
}

.markdown > h1 {
	font-size: 20px;
	font-weight: bold;
	color: #165d7e;
	line-height: 1.5;
	padding-top: 24px;
}

.markdown > h2 {
	font-size: 17px;
	font-weight: bold;
	color: #6da5bf;
	line-height: 1.5;
	padding-top: 16px;
}

.markdown > h3 {
	font-size: 15px;
	font-weight: bold;
	color: #165d7e;
	line-height: 1.5;
	padding-top: 8px;
}

.markdown > p > img {
	width: 100%;
	border-radius: 16px;
}

.markdown table {
	border-spacing: 0;
	border-collapse: collapse;
}

.markdown td,
.markdown th {
	padding: 0;
	color: #165d7e;
}

.markdown table {
	display: block;
	width: 100%;
	overflow: auto;
}

.markdown table th {
	font-weight: 600;
}

.markdown table th,
.markdown table td {
	padding: 6px 13px;
	border: 1px solid #dfe2e5;
}

.markdown table tr {
	background-color: #fff;
	border-top: 1px solid #c6cbd1;
}

.markdown table tr:nth-child(2n) {
	background-color: #f6f8fa;
}

.markdown strong {
	font-weight: inherit;
}

.markdown strong {
	font-weight: bolder;
}

.markdown em {
	text-decoration: line-through;
}

.markdown input {
	font: inherit;
	margin: 0;
}

.markdown input {
	overflow: visible;
}

.markdown [type='checkbox'] {
	-webkit-appearance: checkbox;
	-moz-appearance: checkbox;
	box-sizing: border-box;
	padding: 0;
}

.markdown * {
	box-sizing: border-box;
}

.markdown input {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

.markdown a + br {
	display: none;
}

.markdown hr {
	height: 0;
	margin: 15px 0;
	overflow: hidden;
	background: transparent;
	border: 0;
	border-bottom: 1px solid #e3e3e3;
}

.markdown > * {
	margin-bottom: 16px;
}

.markdown > *:last-child {
	margin-bottom: 0px;
}

.markdown.large ul li {
	list-style-type: disc;
	color: #165d7e;
	margin-left: 16px;
	font-size: 15px;
	line-height: 1.5;
}

.markdown.large ol li {
	list-style-type: decimal;
	color: #165d7e;
	margin-left: 16px;
	font-size: 15px;
	line-height: 1.5;
}

.markdown.large ol li ol li {
	list-style-type: lower-alpha;
}

.markdown.large ol > li > *,
ul > li > * {
	margin-bottom: 8px;
}

.markdown.large li {
	margin-bottom: 8px;
}

.markdown.large li:first-child {
	margin-top: 8px;
}

.markdown.large p {
	color: #165d7e;
	font-size: 15px;
	line-height: 1.8;
}

.markdown.large th,
td {
	font-size: 15px;
	line-height: 1.8;
}

.markdown.large a {
	text-decoration: underline;
}

.markdown.large p > code,
pre {
	border-radius: 4px;
	padding: 4px;
	background-color: #fcfcfc;
	border: 1px solid #c8c8c8;
	font-size: 15px;
	color: #dc267e;
	line-height: 1.5;
}

.markdown.large > h1 {
	font-size: 24px;
	font-weight: bold;
	color: #165d7e;
	line-height: 1.5;
	padding-top: 24px;
}

.markdown.large > h2 {
	font-size: 20px;
	font-weight: bold;
	color: #6da5bf;
	line-height: 1.5;
	padding-top: 16px;
}

.markdown.large > h3 {
	font-size: 17px;
	font-weight: bold;
	color: #165d7e;
	line-height: 1.5;
	padding-top: 8px;
}

.markdown.large > p > img {
	width: 100%;
	border-radius: 16px;
}

.markdown.large table {
	border-spacing: 0;
	border-collapse: collapse;
}

.markdown.large td,
.markdown.large th {
	padding: 0;
	color: #165d7e;
}

.markdown.large table {
	display: block;
	width: 100%;
	overflow: auto;
}

.markdown.large table th {
	font-weight: 600;
}

.markdown.large table th,
.markdown.large table td {
	padding: 6px 15px;
	border: 1px solid #dfe2e5;
}

.markdown.large table tr {
	background-color: #fff;
	border-top: 1px solid #c6cbd1;
}

.markdown.large table tr:nth-child(2n) {
	background-color: #f6f8fa;
}

.markdown.large strong {
	font-weight: inherit;
}

.markdown.large strong {
	font-weight: bolder;
}

.markdown.large em {
	text-decoration: line-through;
}

.markdown.large input {
	font: inherit;
	margin: 0;
}

.markdown.large input {
	overflow: visible;
}

.markdown.large [type='checkbox'] {
	-webkit-appearance: checkbox;
	-moz-appearance: checkbox;
	box-sizing: border-box;
	padding: 0;
}

.markdown.large * {
	box-sizing: border-box;
}

.markdown.large input {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

.markdown.large a + br {
	display: none;
}

.markdown.large hr {
	height: 0;
	margin: 15px 0;
	overflow: hidden;
	background: transparent;
	border: 0;
	border-bottom: 1px solid #e3e3e3;
}

.markdown.large > * {
	margin-bottom: 16px;
}

.markdown.large > *:last-child {
	margin-bottom: 0px;
}

/* react-toggle */
.react-toggle {
	touch-action: pan-x;

	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	transition: opacity 0.25s;
}

.react-toggle-track {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: #4d4d4d;
	transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
	background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #128d15;
}

.react-toggle-track-check {
	position: absolute;
	width: 14px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	transition: opacity 0.25s ease;
}

.react-toggle-track-x {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	right: 10px;
	opacity: 1;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
	opacity: 0;
}

.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 22px;
	height: 22px;
	border: 1px solid #4d4d4d;
	border-radius: 50%;
	background-color: #fafafa;
	box-sizing: border-box;
	transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
	left: 27px;
	border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
	box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	box-shadow: 0px 0px 5px 5px #0099e0;
}

